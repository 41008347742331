<template>
  <div class="act" style="background-image: linear-gradient(to bottom right, #5e5f5e, #f9ece3);">
    <div class="d-flex flex-column min-vh-100">
      <Evenmenubar :title="title" />
      <b-modal
        ref="my-loading"
        id="modal-2"
        title="Loading"
        @hide="onHide"
        centered
        hide-footer
        hide-header
      >
        <div class="text-center">
          <a-spin :spinning="spinning" tip="Loading...">
          <b-tabs content-class="mt-3">
            <b-tab title="建置帳號" active>
              <p>
                <b-card header="">
                    <div style="margin-bottom: 0.6rem;">
                      <b-avatar variant="info" size="4rem" :src="purl" class="mr-2"></b-avatar>
                      <span class="mr-auto" v-text="displayname"></span>
                    </div>
                    <blockquote class="blockquote mb-0">
                      <b-form @submit="onSubmit" @reset="onReset" v-if="show" >
                        <b-form-group id="input-group-3" description label-for="input-9">
                          <label class="d-flex align-items-left" for="input-9">
                            <span style="font-size: 15px;" v-text="'建立顯示名稱 ( 請填寫真實姓名 ) : '"></span>
                          </label>
                          <b-form-input
                            id="input-9"
                            v-model="form.displayname"
                            autocomplete="off"
                            placeholder="請填寫真實中文姓名"
                          ></b-form-input>
                        </b-form-group>

                        <Addresscom @toaddress="toaddress" style="width:160px;float:left;" />
                        <div style="padding:57px;"></div>
                        <b-form-group id="input-group-2" description label-for="input-8">
                          <label class="d-flex align-items-left" for="input-8">
                            <code>*</code>
                            <span style="color:crimson;font-size: 15px;" v-text="'輸入地址 : ⚠街道路號可選填'"></span>
                          </label>
                          <div style="display: flex; justify-content: space-between;">
                          <b-form-input
                            id="input-8"
                            v-model="form.address"
                            autocomplete="off"
                            :disabled="true"
                            style="width:50%"
                            placeholder="選擇縣市鄉鎮"
                            required
                          ></b-form-input>
                          <b-form-input
                            id="input-8"
                            v-model="otherAddress"
                            autocomplete="off"
                            style="width:50%"
                            placeholder="輸入街道路號"
                          ></b-form-input>                     
                          </div> 
                        </b-form-group>
                        <b-form-group id="input-group-11" description label-for="input-sex">
                          <label class="d-flex align-items-left" for="input-sex">
                            <code>*</code>
                            <span style="color:crimson; font-size: 15px;">輸入性別 (男/女)</span>
                          </label>
                          <b-form-select
                            id="input-sex"
                            v-model="form.sex"
                            :options="[{ value: 'male', text: '男' }, { value: 'female', text: '女' }, { value: 'other', text: '其它' }]"
                            placeholder="請選擇性別"
                            required
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group id="input-group-12" description label-for="input-birth">
                          <label class="d-flex align-items-left" for="input-birth">
                            <code>*</code>
                            <span style="color:crimson; font-size: 15px;">輸入生日 (格式：YYYY-MM-DD)</span>
                          </label>
                          <b-form-input
                            type="date"
                            id="input-birth"
                            v-model="form.birth"
                            autocomplete="off"
                            placeholder="請輸入生日"
                            required
                          ></b-form-input>
                        </b-form-group>
                        
                        
                        <b-form-group
                          id="input-group-2"
                          v-if="user.data.user.username==''"
                          :description="result? '該帳號已存在, 請使用其它帳號':msgs?'請勿輸入中文':''"
                          label-for="input-2"
                        >
                          <label class="d-flex align-items-left" for="input-2">
                            <code>*</code>
                            <span style="color:crimson;font-size: 15px;" v-text="'建立帳號 : (以手機號碼作為帳號)'"></span>
                          </label>
                          <b-form-input
                            id="input-2"
                            v-model="form.account"
                            @input="checkAccount"
                            aria-autocomplete="none"
                            role="presentation"
                            autocomplete="off"
                            placeholder="建立帳號, 請輸入手機號碼作為帳號"
                            type="number"
                            required
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group
                          id="input-group-3"
                          v-if="user.data.user.password==''"
                          label-for="input-3"
                        >
                          <label class="d-flex align-items-left" for="input-3">
                            <code>*</code>
                            <span style="color:crimson;font-size: 15px;" v-text="'建立密碼 :'"></span>
                            <span v-if="form.password!==form.password2 && (form.password2!=='' && form.password1!=='')" style="color:crimson;font-size: 15px;" v-text="' ✘ 密碼不一致, 請確認'"></span>
                          </label>
                          <b-form-input
                            id="input-3"
                            type="password"
                            v-model="form.password"
                            autocomplete="new-password"
                            placeholder="建立密碼"
                            required
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group
                          id="input-group-4"
                          v-if="user.data.user.password==''"
                          :description="form.password===form.password2 && (form.password2!=='' && form.password1!=='')  ? '謝謝您,的輸入':form.password2=''"
                          label-for="input-4"
                        >
                          <label class="d-flex align-items-left" for="input-4">
                            <code>*</code>
                            <span style="color:crimson;font-size: 15px;" v-text="'請再次輸入密碼 :'"></span>
                          </label>
                          <b-form-input
                            id="input-4"
                            type="password"
                            v-model="form.password2"
                            autocomplete="off"
                            placeholder="請再輸入密碼一次"
                            required
                          ></b-form-input>
                        </b-form-group>
                        <a-button-group>
                          <a-button
                            type="primary"
                            html-type="submit"
                            style="width: 220px; height: 50px; font-size: 16px;"
                            :loading="iconLoading"
                            :disabled=" form.account==='' || form.password==='' || form.password2==='' || form.password!==form.password2 ||  form.address==='' || form.sex==='' || form.birth===''"
                          >送出</a-button>
                        </a-button-group>
                      </b-form>
                    </blockquote>
                  </b-card>
              </p>
            </b-tab>
            <b-tab v-if="this.user.data.user.utoken!==''" title="LINE與既有帳號綁定">
              <p>
                <b-card header="既有帳號登入(綁定LINE帳號)">
                  <a-form>
                    <a-form-item>
                        <template #label>
                          <span style="color: red;">{{ binMsg }}</span>
                        </template>                      
                        <a-input 
                            v-model="myAccount" 
                            placeholder="請輸入帳號"
                        ></a-input>
                        <a-input 
                            v-model="myPassword" 
                            placeholder="請輸入密碼"
                        ></a-input> 
                        <div >                                  
                            <a-button-group>
                                <a-button @click="bindBtn()" :disabled="myAccount=='' || myPassword==''" style="width: 220px; height: 50px; font-size: 16px;" type="primary">登入</a-button>
                            </a-button-group>                            
                        </div>                   
                    </a-form-item>
                  </a-form>                    

                </b-card>                
              </p>
            </b-tab>          
          </b-tabs>

        </a-spin>

        </div>
      </b-modal>
      <div class="wrapper flex-grow-1"></div>
      <Efootbar />
    </div>
  </div>
</template>
<script>
import Evenmenubar from "@/components/Evenmenubar.vue";
import Efootbar from "@/components/Efootbar.vue";
import Addresscom from "../components/Addresslist.vue";
import router from "../router";
import { userService } from "../_services/";
import store from "../store/index";
import { mapState, mapActions, mapMutations } from "vuex";

const postData = (params) => {
  return userService.myLogin(params);
};


const putData = (params) => {
  return userService.myPutLogin(params);
};


export default {
  name: "Setting",
  components: {
    Evenmenubar,
    Efootbar,
    Addresscom
  },
  data() {
    return {
      disableds: false,
      title: { title: "建立帳號", logo: "setting" },
      otherAddress:"",      
      form: {
        displayname:"",
        email: "",
        account: "",
        address: "",
        birth:"",
        sex:"",
        hphone:"",
        password: "",
        password2: "",
        citys:'', 
        villages:'', 
        towns:'',         
      },
      myAccount:"",
      myPassword:"",
      tmpForm:{
        account:this.makeCode(),
        password:"a1234",
        address:"",
        birth:"",
        sex:"",        
        hphone:"",
        citys:'', 
        villages:'', 
        towns:'',            
      },
      spinning:false,
      binMsg:'',
      autoMsg:'',
      show: true,
      DCheck: false,
      purl: "",
      iconLoading: false,
      displayname: "",
      result: false,
      msgs: false
    };
  },
  methods: {
    ...mapActions("account", ["logout", "checktoken","binLogin"]),
    ...mapMutations("account", ["updateProfile","loginSucces"]),
    async onSubmit(event) {
      event.preventDefault();
      let results1 = await userService.checkAcct(this.form.account).then(rs => {
        return rs.data.verify;
      });
      if(this.DCheck){
        results1=false
      }
      if (results1===false) {
        this.iconLoading = true;
        var playload = {
          use: false,
          email: this.form.email,
          password: this.form.password,
          username: this.form.account,
          display: this.form.displayname!==""?this.form.displayname:this.displayname,
          address: this.form.address+this.otherAddress.toString().trim(),
          birth:this.form.birth,
          sex:this.form.sex,
          hphone: this.form.hphone,
          priUrl: this.purl,
          citys:this.form.citys,   
          villages:this.form.villages,
          towns:this.form.towns,   
        };
        this.updateProfile(playload);
        console.log(playload);
        this.user.data.use = false;
        this.user.data.user.email = this.form.email;
        this.user.data.user.password = this.form.password;
        this.user.data.user.username = this.form.account;
        this.user.data.user.display_name = this.form.displayname!==""?this.form.displayname:this.displayname;
        this.user.data.user.pri_url = this.purl;
        this.user.data.user.hphone = this.form.hphone;
        this.user.data.user.address = this.form.address+this.otherAddress.toString().trim();
        this.user.data.user.birth=this.form.birth;
        this.user.data.user.sex=this.form.sex;
        this.user.data.user.citys =this.form.citys;
        this.user.data.user.villages = this.form.villages;
        this.user.data.user.towns =this.form.towns;   

        console.log(this.user.data.user);
        let results2 = await userService
          .upprofile(this.user.data.user)
          .then(rs => {
            if (rs.status === 201) {
              this.iconLoading = false;
              let names = store.state.account.targetpage;
              return names;
            } else {
              return "";
            }
          });
        //console.log("results2 : ",results2)
        if (results2 == null || results2 === "") {
          router.push("/");
        } else {
          router.replace({ name: results2 }).catch(err => {
            err;
          });
        }
      }
      return false
    },    
    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
        //this.handleBackdrop();
      }
    },
    toaddress(val,city,village,town) {
      this.form.address = val;
      this.form.citys=city
      this.form.villages = village
      this.form.towns = town 
    },
    toTmpAddress(val,city,village,town) {
      this.tmpForm.address = val;
      this.tmpForm.citys=city
      this.tmpForm.villages = village
      this.tmpForm.towns = town 
    },    

    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.account = "";
      this.form.address = "";
      this.form.birth = "";
      this.form.sex = "";     
      this.form.hphone ="";
      this.form.displayname ="";
      this.form.password = "";
      this.form.password2 = "";
      this.form.citys="";
      this.form.villages ="";
      this.form.towns = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    async checkAccount() {
      var pattern = new RegExp("[\u4E00-\u9FA5]");
      const taiwanMobilePattern = /^09\d{8}$/; // 台灣手機號碼正則表達式  
      let tmp = this.form.account
      this.form.account = tmp.toString().trim()
      if (this.form.account === "") {
        this.form.account = ""
        this.msgs=true
      } else if (!taiwanMobilePattern.test(this.form.account)) {
        this.form.account = ""
        this.msgs=true
      } else {
        this.msgs=false
      }  
      if(pattern.test(this.form.account)){
        this.form.account = ""
        this.msgs=true
      }else{
        this.msgs=false
      }
      if (this.form.account) {
        this.result = await userService
          .checkAcct(this.form.account)
          .then(rs => {
            return rs.data.verify;
          });
        if (this.result) {
          this.form.account = "";
          this.form.hphone = "";
        }else{
          this.form.hphone = this.form.account
        }
      }
    },
    trimStr(){
       let tmp1 = this.form.displayname
       let tmp2 =  this.form.email
       let tmp3 =  this.form.account
       let tmp4 = this.form.address
       let tmp5 =  this.form.password
       let tmp6 =   this.form.password2
       let tmp7 = this.form.hphone
       let tmp8 =  this.form.citys
       let tmp9 =   this.form.villages
       let tmp10 = this.form.towns   
       let tmp11 =   this.form.birth
       let tmp12 = this.form.sex          

       this.form.displayname = tmp1.toString().trim()
       this.form.email = tmp2.toString().trim()
       this.form.account = tmp3.toString().trim()
       this.form.address = tmp4.toString().trim()
       this.form.password = tmp5.toString().trim()
       this.form.password2 = tmp6.toString().trim()
       this.form.hphone = tmp7.toString().trim()
       this.form.citys = tmp8.toString().trim()
       this.form.villages = tmp9.toString().trim()
       this.form.towns = tmp10.toString().trim()          
       this.form.birth = tmp11.toString().trim()
       this.form.sex  = tmp12.toString().trim() 
    },

    async bindBtn() {
      if(this.myAccount!=='' && this.myPassword!==''){
          let item = {
          id:this.user.data.user.id,
          username: this.myAccount,
          password: this.myPassword, 
          utoken:this.user.data.user.utoken,  
          pri_url:this.user.data.user.pri_url     
        };
        this.spinning = true;
        let rs = await this.myLogin(item)
        if (rs.code== 200){
          this.spinning = false;
          this.binMsg="綁定成功"
          let targetPages = store.state.account.targetpage;
          setTimeout(() => {
            this.binLogin({username: this.myAccount,password: this.myPassword,targetPage:targetPages})
          }, 1000);
        }else if(rs.code== 404){
          this.binMsg="請確認密碼, 或是帳不是否存在, 或已被綁定"
          this.spinning = false;
        }
      }
    },

    
    async autoBtn() {
      let item = {
        id:this.user.data.user.id,
        username: this.tmpForm.account,
        password: "a1234", 
        utoken:this.user.data.user.utoken, 
        address:this.tmpForm.address,
        birth:this.tmpForm.birth,
        sex:this.tmpForm.sex,
        hphone:this.tmpForm.hphone,
        citys:this.tmpForm.citys,
        villages:this.tmpForm.villages,
        towns:this.tmpForm.towns,       
      };
      this.spinning = true;
      let rs = await this.myUpdateLogin(item)
      if (rs.code== 200){
        this.spinning = false;
        this.autoMsg="自動產生帳號成功"
        //setTimeout(this.login({username: this.tmpForm.account,password: this.tmpForm.password}), 1000);
        let targetPages = store.state.account.targetpage;
        this.binLogin({username: this.tmpForm.account,password: this.tmpForm.password,targetPage:targetPages});

      }else if(rs.code== 404){
        this.autoMsg="自動產生帳號失敗, 請洽系統人員"
      }
    },    

    myLogin(item) {
      return new Promise((resolve) => {         
          postData({
            ...item,
          }).then(({ data }) => {
              resolve(data);            
          });
        });
      }, 
      
      myUpdateLogin(item) {
      return new Promise((resolve) => {         
          putData({
            ...item,
          }).then(({ data }) => {
              resolve(data);            
          });
        });
      },

              
        makeCode() {
           let identifyCodes= '123456789abcdefghijklmnopqstuwxyzABCDEFGHGKMNPQRSTUVWXYZ'
           let tmp =''
            for (let i = 0; i<4; i++) {
              tmp += identifyCodes[
                    Math.floor(Math.random() * (identifyCodes.length - 0) + 0)
                    ]
            } 
            var time = new Date();
            var theTime = time.getTime(); //1558492972644
          return tmp+theTime; 
         },      


  },
  
  computed: {
    ...mapState("account", ["status", "user"]),
    myAddressCheck() {
        let tmp = this.form.address.toString().trim()
        return tmp.length>0? false:true;
      },     
  },
  mounted() {
    this.purl = this.user.data.user.pri_url;
    this.displayname = this.user.data.user.display_name;
    if (this.user.data.user.username != "") {
      this.form.account = this.user.data.user.username;
      this.form.email = this.user.data.user.email.toString().trim();
      this.form.hphone = this.user.data.user.hphone.toString().trim();
      this.form.displayname = this.displayname.toString().trim();
      this.form.address = this.user.data.user.address.toString().trim();

      this.form.birth=this.user.data.user.birth.toString().trim();
      this.form.sex=this.user.data.user.sex.toString().trim();

      this.form.password = this.user.data.user.password;
      this.form.password2 = this.user.data.user.password;

      this.form.citys = this.user.data.user.citys.toString().trim()
      this.form.villages = this.user.data.user.villages.toString().trim()
      this.form.towns = this.user.data.user.towns.toString().trim()  

      this.DCheck = true
    }
    this.show = this.status.use;
    if (!this.status.use) {
      let names = store.state.account.targetpage;
      if (names !== "") {
        router.replace({ name: names }).catch(err => {
          err;
        });
      } else {
        router.push("/");
      }
    }
    this.checktoken();
    this.$refs["my-loading"].show();
  }
};
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

div.ex3 {
  width: 800px;
  height: 460px;
  overflow: auto;
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #000000;
}

#style-3 {
  text-align: left;
}
</style>